// extracted by mini-css-extract-plugin
export var ArtistDescription = "DanielArsham-module--ArtistDescription--PTSx2";
export var ArtistInfos = "DanielArsham-module--ArtistInfos--P8rsT";
export var ButtonWrapper = "DanielArsham-module--ButtonWrapper --kWKBq";
export var CardWrapper = "DanielArsham-module--CardWrapper--9XKSZ";
export var CarrouselWrapper2 = "DanielArsham-module--CarrouselWrapper2--GeXIO";
export var Citations = "DanielArsham-module--Citations--uF2kU";
export var DescriptionWrapper = "DanielArsham-module--DescriptionWrapper--R4YH1";
export var ImageWrapper = "DanielArsham-module--ImageWrapper--4+KHY";
export var LinkWrapper = "DanielArsham-module--LinkWrapper--2n3f1";
export var MobileProtrait = "DanielArsham-module--MobileProtrait--sMRrc";
export var More = "DanielArsham-module--More--0JkQy";
export var MoreButton = "DanielArsham-module--MoreButton--baob2";
export var NameWrapper = "DanielArsham-module--NameWrapper--luigq";
export var PdpWrapper = "DanielArsham-module--PdpWrapper--iyP0w";
export var PhotosWrapper = "DanielArsham-module--PhotosWrapper--DZHXA";
export var ProfilWrapper = "DanielArsham-module--ProfilWrapper--fnXPB";
export var TitleWrapper = "DanielArsham-module--TitleWrapper--tVAx+";
export var Wrapper = "DanielArsham-module--Wrapper--NuMwX";