
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
  ExpoWrapper
} from "./DanielArsham.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import DanielArshamPhoto1 from "../../../../res/Photos site/Daniel Arsham/ARSHAM.png"
import DanielArshamPhoto2 from "../../../../res/Photos site/Daniel Arsham/Daniel Arsham .jpg"
import DanielArshamPhoto3 from "../../../../res/Photos site/Daniel Arsham/3.png"
import DanielArshamPhoto4 from "../../../../res/Photos site/Daniel Arsham/4.jpg.webp"
import DanielArshamPhoto5 from "../../../../res/Photos site/Daniel Arsham/5.jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import DanielArsham1 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Daniel Arsham - Ash Eroded Film Reel - 2013.png"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/arsham/portrait.jpg'

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "DanielArsham",
  name: "DanielArsham",
  description:"Daniel Arsham est un artiste américain né en 1980 qui vit et travaille à New York. Il a étudié à la Cooper Union for the Advancement of Science and Art de New York et développe ses recherches entre art, architecture et performance, jouant notamment avec les formes, les espaces et les volumes. L’artiste a par ailleurs collaboré avec de nombreuses maisons de luxe telles que Dior en 2020. Daniel Arsham réalise principalement des sculptures qui pourraient être décrites comme des archéologies du futur, entre pop art et romantisme présentées au sein d’installations et de performances. En parallèle de ses créations personnelles, l'artiste réalise des collaborations originales explorant d’autres formes artistiques comme la danse avec notamment la création d’une scène originale pour le chorégraphe Merce Cunningham. En 2013, l'artiste collabore avec Pharrell Williams, pour lequel il recrée son premier clavier en cendres volcaniques.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de DanielArsham.",
  photos: [
    { src: DanielArshamPhoto1, name: "DanielArsham" },
    { src: DanielArshamPhoto3, name: "DanielArsham" },
    { src: DanielArshamPhoto4, name: "DanielArsham" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: [
  "« I think there are two types of artists. There are the kinds that make work not necessarily in a vacuum, but the work is more made for themselves—it’s an outward expression or a way to understand the world around you. And then there are other artists whose work is a kind of interpretation of existing times that has the express intention of communicating some sort of tangent that people aren’t really seeing. I’m in the latter category, so my work is really about trying to understand and parse through this strange existence, and then to reveal things that are just outside of our everyday experiences. Most of the work I make involves using things that are already around that reference ancient Greek or Roman works, or manipulating the surface of architecture and playing with people’s understanding or conception of time ».",
  ]
};

const infos2 = {
  photos: [
    { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
  ],
};

const DanielArsham = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>DANIEL ARSHAM <h2 style={{paddingLeft: "16px"}}>(American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1980</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />

        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default DanielArsham;